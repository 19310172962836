import axiosHttpService from '@/core/utils/axios';
import { BaseResultDto } from '../baseDto/resultDto';
import { RegRecordPage, ZSDateRange } from './dtos/common'

export class CommonService {
    //获取招生时间范围
    getZSDateRange() {
        return axiosHttpService.get<ZSDateRange>({
            url: 'Common/GetZSDateRange',
        })
    }
    //获取报名记录
    getRegRecordPage(account: string) {
        return axiosHttpService.get<RegRecordPage>({
            url: "Common/GetRegRecordPage",
            params: {
                account
            }
        })
    }
    //获取报名是否开放
    checkZSOpen() {
        return axiosHttpService.get<boolean>({
            url: "Common/CheckZSOpen",
        })
    }
    //验证是否已提交补充材料
    submitSupply(refId: string, isUpdate: boolean) {
        return axiosHttpService.post<BaseResultDto>({
            url: "Common/SubmitSupply",
            params: {
                refId,
                isUpdate
            }
        })
    }
}

export default new CommonService();
import axiosHttpService from '@/core/utils/axios';

export class ReadRecordService {
    getReadResult() {
        return axiosHttpService.get<boolean>({
            url: 'ReadRecord/GetReadResult',
        })
    }
    saveReadRecord() {
        return axiosHttpService.post<number>({
            url: 'ReadRecord/SaveReadRecord',
        })
    }
}

export default new ReadRecordService();
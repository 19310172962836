import axiosHttpService from '../../../core/utils/axios';
import { Toast } from "vant";
import { BaseUrl } from '@/config';

export class SuiShenBanSSOService {
    getSuiShenBan(code: string): void {
        axiosHttpService.getAxiosByApiUrl()
            .get('helper/GetSuiShenBan?code=' + code)
            .then((res: any) => {
                sessionStorage.clear();
                sessionStorage.setItem('currentUser', JSON.stringify(res.data.result));
                // localStorage.clear();
                // localStorage.setItem('currentUser',JSON.stringify(res.data.result));
                window.location.href = BaseUrl;
            })
            .catch((err: any) => {
                Toast.fail(err.response.data.error.message);
            })
    }
    getAppletUser(token: string, platform: string): void {
        axiosHttpService.getAxiosByApiUrl()
            .get(`helper/GetAppletUserInfo?token=` + token + `&platform=` + platform)
            .then((res: any) => {
                sessionStorage.clear();
                sessionStorage.setItem('currentUser', JSON.stringify(res.data.result));
                window.location.href = BaseUrl;
            }).catch((err: any) => {
                Toast.fail(err.response.data.error.message);
            })
    }
    getSPTUserInfo(token: string): void {
        axiosHttpService.getAxiosByApiUrl()
            .get(`helper/getSPTUserInfo?access_token=` + token + `&source=0`)
            .then((res: any) => {
                sessionStorage.clear();
                sessionStorage.setItem('currentUser', JSON.stringify(res.data.result));
                window.location.href = BaseUrl;
            }).catch((err: any) => {
                Toast.fail(err.response.data.error.message);
            })
    }
}
export default new SuiShenBanSSOService();

// 检查是否登录
export function checkIsLogin() {
    // const currentUserText = localStorage.getItem('currentUser');
    const currentUserText = sessionStorage.getItem('currentUser');
    if (!currentUserText) {
        // const nowTime = new Date().getTime();
        // // 加上时间戳
        // axiosHttpService.getAxiosByApiUrl()
        //     .get(process.env.VUE_APP_SignUrl + nowTime)
        //     .then((res: any) => {
        //         const url = process.env.VUE_APP_EshiminUrl + `&timestamp=${nowTime}&sign=${res.data.result}`;
        //         window.location.href = url;
        //     });
        window.location.href = process.env.VUE_APP_LoginUrl;
    }
}

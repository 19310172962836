<!-- 关闭的时候使用当前页面替换index.vue -->
<template>
  <div class="home-div">
    <iframe class="customHeader_iframe" scrolling="no"
      src="https://zwdt.sh.gov.cn/zwdtSW/dphead/head/head.jsp?name=SH00HP"></iframe>
    <div class="home-title">{{ $messageConfig.systemTitle }}</div>
    <div class="home-content">
      <el-card dis-hover>
        <div class="tips-item">
          <div class="title-div fl">报名时间：</div>
          <div class="content-div">
            2024年5月9日--5月17日（已结束）
          </div>
        </div>
        <div class="tips-item">
          <div class="title-div fl" style="width: 190px">
            适龄幼儿报名范围：
          </div>
          <div class="content-div">
            <template v-for="(item, index) in zsRange">
              <span :key="index"> {{ item.title }}{{ item.info }} </span>
              <br :key="'br_' + index" v-if="index != zsRange.length - 1" />
            </template>
          </div>
        </div>
        <div class="tips-item">
          <div class="title-div fl">温馨提示：</div>
          <div class="content-div">
            <span>1.报名前请仔细阅读本区适龄幼儿招生入园政策</span><br />
            <span>2.幼儿报名顺序对录取结果无影响</span>
          </div>
        </div>
      </el-card>

      <el-card dis-hover class="registration-tips">
        <div class="title-div">公告</div>
        <ul>
          <li class="notice-item" v-for="(item, index) in noticeList" :key="index" @click="viewNotice(item.url)">
            <i class="el-icon-document"></i>{{ item.name }}
          </li>
        </ul>
      </el-card>
      <div class="text-center pt-16">
        <ssb-primary-button @click.native="toRegistration()">幼儿报名（已结束）
        </ssb-primary-button>
        <ssb-primary-button @click.native="viewPolicy()">本区招生政策查询
        </ssb-primary-button>
      </div>
    </div>
    <div class="homt-footer">
      <p class="tips">推荐使用Chrome浏览器或者Edge浏览器访问！</p>
      <p>
        咨询电话：63288532 咨询时间：周一到周五9:00-11:00，13:30-17:00（法定节假日休息）
      </p>
      <p>版权所有：上海市黄浦区教育局</p>
    </div>

    <iframe class="customFooter_iframe" scrolling="no"
      src="https://zwdt.sh.gov.cn/zwdtSW/dphead/foot/foot.html"></iframe>

    <!--报名须知-->
    <el-dialog v-if="showDialog" :visible.sync="showDialog" custom-class="tips-dialog" :close-on-click-modal="false">
      <template slot="title">
        <div>
          <div>{{ year }}年黄浦区适龄幼儿入园报名系统</div>
          <span class="color-red">特别告知 </span>
        </div>
        <el-image class="tips-icon" :src="icon" :fit="fit"></el-image>
      </template>
      <p>亲爱的家长：</p>
      <div class="text-indent">
        <p>
          本系统为您提供黄浦区适龄幼儿入园报名、入托需求登记等服务，小班适龄幼儿报名需先在“上海市适龄幼儿入园信息登记系统”完成统一的信息登记。开始报名前请您仔细阅读
          《{{ year }}年黄浦区幼儿园招生入园工作实施意见》。
        </p>
        <p>
          报名或登记用户必须是幼儿的法定监护人。本系统内预置的幼儿基本信息来自于上海市大数据中心数据库和上海市适龄幼儿入园信息登记系统，且仅用于黄浦区适龄幼儿入园报名，请您认真核对。
        </p>
        <p>
          请根据您和您孩子所持证件的信息完整、准确地填报相关信息。您需承诺填报的信息真实有效。
        </p>
      </div>
      <p class="message-p">阅读本告知后，请您输入以下声明：</p>
      <el-form ref="formModel" :model="formModel" :rules="formRule" label-position="left" class="dialog-form">
        <el-form-item label="我已阅读知晓" prop="textarea">
          <el-input v-model="formModel.textarea" type="textarea" :autosize="{ minRows: 2, maxRows: 5 }"
            placeholder="请在文本框内输入：“我已阅读知晓”"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitFormModel">确 定</el-button>
      </div>
    </el-dialog>

    <!--通知去入园登记-->
    <el-dialog :visible.sync="showDialog2" custom-class="tips-dialog" title="特别告知">
      <p>亲爱的家长：</p>
      <div class="text-indent">
        <p>
          黄浦区适龄幼儿入园报名目前尚未开始，请先完成上海市适龄幼儿入园信息登记。点击“确认”，可跳转至上海市适龄幼儿入园信息登记系统进行信息登记。
        </p>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取 消</el-button>
        <el-button type="primary" @click="toRegister">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import "./index.less";
// import pdf from "vue-pdf"; // 引入pdf组件
import { readRecordService } from "@/app/apiServices/readRecord/public-api";
import suiShenBanSSOService from "@/app/suiShenBanSSO/services/suiShenBanSSOService";
import { checkIsLogin } from "@/app/suiShenBanSSO/services/suiShenBanSSOService";
import { noOpen, noRegister, registerEnd } from "@/config/message";
import commonService from "@/app/apiServices/common/commonService";
export default {
  // components: { pdf },
  data() {
    return {
      formModel: {
        textarea: "",
      },
      formRule: {
        textarea: [
          { required: true, message: "请输入“我已阅读知晓”", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value === "我已阅读知晓") {
                callback();
              } else {
                callback(new Error("输入错误，请输入“我已阅读知晓”"));
              }
            },
            trigger: "blur",
          },
        ],
      },
      //是否已阅读须知
      isRead: false,
      showDialog: false,

      //是否开放招生
      isOpen: false,
      showDialog2: false, //前往市登记平台登记的告知

      message: "", //提示语

      icon: require("../../assets/image/message_img.png"),

      //公告
      noticeList: [
        {
          name: "2024年黄浦区幼儿园招生入园工作实施意见",
          url: "https://www.shhuangpu.gov.cn/zw/009002/009002004/009002004004/009002004004003/009002004004003001/20240416/23ba0d00-2e1d-4964-aedc-84a2bb49252d.html",
        },
        {
          name: "2024年黄浦区学前教育阶段适龄幼儿入园工作政策问答",
          url: " https://www.shhuangpu.gov.cn/zw/009002/009002004/009002004002/009002004002003/20240416/57eb06fa-c2f4-4146-bc90-a8df526f5deb.html",
        },
        {
          name: "一图看懂：2024年黄浦区适龄幼儿招生入园工作",
          url: "https://www.shhuangpu.gov.cn/zw/009002/009002004/009002004002/009002004002003/20240416/f0e85652-d642-44ef-acd7-31bc7bb3237e.html",
        },
        {
          name: "黄浦区2024年公办幼儿园招生信息及园所开放日",
          url: "https://yjzs.hpe.cn/YEZS.WebApi/AdminissionNotes/黄浦区公办幼儿园招生信息及园所开放日.pdf",
        },
      ],
      //招生范围
      zsRange: [
        {
          title: "托班新生报名：",
          info: "",
        },
        {
          title: "小班新生报名：",
          info: "",
        },
        {
          title: "中班插班报名：",
          info: "",
        },
        {
          title: "大班插班报名：",
          info: "",
        },
      ],

      //当前年份
      year: 2022,
    };
  },
  methods: {
    //幼儿报名
    toRegistration() {
      //获取是否开放招生
      // commonService.checkZSOpen().then((res) => {
      //   if (res == 0) {
      //     alert(noOpen);
      //   } else {
      //     readRecordService.getReadResult().then((ret) => {
      //       if (ret) {
      //         this.$router.push("/record");
      //       } else {
      //         this.showDialog = true;
      //       }
      //     });
      //   }
      // });
      alert(registerEnd);
    },
    //查看公告
    viewNotice(url) {
      window.open(url);
    },
    //查看招生政策
    viewPolicy() {
      window.location.href = this.noticeList[0].url;
    },
    //前往市平台登记
    toRegister() {
      window.location.href = "http://yunpianryt.age06.com/mobile";
    },

    submitFormModel() {
      const { formModel } = this.$refs;
      formModel.validate((valid) => {
        if (valid) {
          readRecordService
            .saveReadRecord()
            .then((ret) => {
              if (ret >= 0) {
                this.showDialog = false;
                this.$router.push("/record");
              } else {
                this.$message.error("操作失败！");
              }
            })
            .catch((error) => {
              this.$message.error("网络异常，请稍后重试");
            });
        }
      });
    },

    //获取招生范围
    getZSRange() {
      const formart = "YYYY年MM月DD日";

      //获取招生时间范围
      commonService.getZSDateRange().then((res) => {
        this.zsRange[0].info =
          "原则上从" +
          this.$date.formatDate(new Date(res.tB_StartDate), formart) +
          "~" +
          this.$date.formatDate(new Date(res.tB_EndDate), formart) +
          "出生";

        this.zsRange[1].info =
          this.$date.formatDate(new Date(res.xB_StartDate), formart) +
          "~" +
          this.$date.formatDate(new Date(res.xB_EndDate), formart) +
          "出生";

        this.zsRange[2].info =
          this.$date.formatDate(new Date(res.zB_StartDate), formart) +
          "~" +
          this.$date.formatDate(new Date(res.zB_EndDate), formart) +
          "出生";

        this.zsRange[3].info =
          this.$date.formatDate(new Date(res.dB_StartDate), formart) +
          "~" +
          this.$date.formatDate(new Date(res.dB_EndDate), formart) +
          "出生";

        this.$store.dispatch("ACTION_ZsDateRange", res);
      });
    },
  },
  mounted() {
    this.year = new Date().getFullYear();
    this.getZSRange();

    // 本地开发不登录
    if (process.env.VUE_APP_ENV != "DEV") {
      var name = "access_token";
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r = window.location.search.substr(1).match(reg); //匹配目标参数

      name = "platform";
      reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
      var r1 = window.location.search.substr(1).match(reg); //匹配目标参数
      if (r != null) {
        if (r1 != null) {
          suiShenBanSSOService.getAppletUser(unescape(r[2]), unescape(r1[2]));
        } else {
          suiShenBanSSOService.getSPTUserInfo(unescape(r[2]));
        }
      } else {
        checkIsLogin();
      }
    }
  },
};
</script>